.projects {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .projectsHeader h1 {
    font-size: 80px;
    color: #d8cd80;
    text-shadow: 2px 2px 2px var(--body_color);
  }

  .projects .projectTitle {
    text-align: center;
    color: #1c2135;
    font-size: 60px;
    font-weight: bolder;
  }
  
  .projectList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }

  .projectList h2 {
    color: #1c2135;
  }
  .projectList p {
    color: #1c2135;
  }
  
  /* MENU ITEM STYLING */
  
  .projectItem {
    border-radius: 15px;
    width: 300px;
    height: 300px;
    margin: 20px;
    background-color: #d8cd80;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .projectItem:hover {
    cursor: pointer;
  }
  .projectItem .bgImage {
    border-bottom: transparent 1px solid;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 300px;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  } 
  .projectItem .bgImage span{
    border-bottom: transparent 2px solid;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: var(--body_color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--body_background);
    opacity: 0.9;
  }

  .projectItem .bgImage p {
    font-size: 14px;
    margin: 0%;
    color: var(--body_color)
  }
  .projectItem .bgImage svg {
    margin-top: 10px;
    font-size: 14px;
  }

  .projectItem .info {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .projectItem .info .name {
    position: relative;
    width: 100%;  
    font-size: 25px;
    font-weight: bold;
    margin: 0%;
  }
  .projectItem .info .date {
    font-size: 18px;
  }

  @media only screen and (max-width: 1300px) {
    .projectList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      grid-template-columns: 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      width: 100%;
    }
    .projectItem {
      width: 300px;
      height: 300px;
    }
  }