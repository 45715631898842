.container button{
    z-index: 20;
    background-color: transparent;
    border: none;
    color: var(--navbar_color);
    cursor: pointer;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.restSkills{
    position: relative;
    height: 0px;
}

.container{
    width: auto;
    background: transparent;
    padding: 10px 20px;
    border-radius: 15px;
    transition: 0.3s ease-in-out;
}

.row{
    width: 95%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
}

.restSkills .row{
    width: 95%;
    height: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
}


.skill-icon{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}
.skill-box {
    width: 92%;
    margin: 25px 0;
    animation: progress 0.3s ease-in-out forwards;
}

.skill-box .title{
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: var(--body_color);
}

.skill-box .skill-bar{
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(0,0, 0, 0.1);
}

.skill-box .skill{
    position: relative;
    display: block;
    height: 100%;
    width: 5%;
    border-radius: 6px;
    background: var(--purple_color);
    opacity: 0;
}

.skill-box .skillInView{
    position: relative;
    display: block;
    height: 100%;
    width: 0%;
    border-radius: 6px;
    background: var(--purple_color);
    animation: progress 1s ease-in-out forwards;
    opacity: 0;
    animation-delay: 0.2s;
}


@keyframes progress {
    0% {
        width: 0;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.skillInView .tooltip{
    position: absolute;
    right: -30px;
    top: -30px;
    font-size: 18px;
    font-weight: 500;
    color: var(--navy_color);
    padding: 2px 6px;
    border-radius: 3px;
    background: var(--yellow_color);
    z-index: 1;
}

.tooltip::before {
    content: '';
    position: absolute;
    left: 20%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background: var(--yellow_color);
    transform: translateX(-50%) rotate(45deg);
}

@media screen and (max-width: 768px){
    .skill-icon{
        width: 40px;
        margin-right: 12px;
    }
    .skill-box {
        width: 90%;
    }
    .skill-box .title{
        text-align: left;
    }
}