.education {
    width: 100%;
    height: 100%;
}

.internship {
    width: 100%;
    height: 100%;
}

.vertical-timeline-element--date{
    color: var(--body_color);
    font-size: 24px;
}
.vertical-timeline-element--organization {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.vertical-timeline-element--organization h3 {
    font-size: 24px;
    color: #1c2135;
    margin: 0;
}
.vertical-timeline-element--organization h3:hover {
    font-size: 24px;
    color: var(--purple_color);
    margin: 0;
    transition: none;
}

.vertical-timeline-element--organization a:link {
    font-size: 24px;
    color: #1c2135;
    margin: 0;
    text-decoration: underline; 
}

.vertical-timeline-element--organization a:visited {
    font-size: 24px;
    color: #1c2135;
    margin: 0;
    text-decoration: none;
}

.vertical-timeline-element--organization a:hover {
    font-size: 24px;
    color: var(--purple_color);
    margin: 0;
    text-decoration: underline;
    transition: none;
}

.vertical-timeline-element--organization a:active {
    font-size: 24px;
    color: #1c2135;
    margin: 0;
    text-decoration: none;
}

.vertical-timeline-element--position {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.vertical-timeline-element--position h4 {
    font-size: 16px;
    color: #1c2135;
    margin: 10px;
    font-style: italic;
}

@media only screen and (max-width: 1169px) {
    .vertical-timeline-element--date {
        color: #1c2135;
    }
}