.footer {
    width: 100%;
    height: 200px;
    background: #1c2135;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 200px;
  }
  
  .socialMedia svg {
    color: #e7e9ee;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    color: #e7e9ee;
    background-color: #1c2135;
  }
  
  @media only screen and (max-width: 600px) {
    .footer {
      font-size: 12px;
    }
    .footer svg {
      margin: 5px;
      font-size: 50px;
    }
  }