  .home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
  }
  
  .about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--navbar_background);
    color: var(--navbar_color);
  }

  .propic img {
    width: max(15vw, 150px) ;
    height: max(15vw, 150px) ;
    display: flex;
    border-radius: 50%;
    border: 5px solid #d8cd80;
    margin: 10px;
  }
  
  .about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 80px;
    color: #d8cd80;
    height: 50px;
    text-shadow: 2px 2px 2px var(--navbar_color);
  }
  
  .prompt {
    width: 40%;
    font-size: 30px;
  }

  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: var(--navbar_color);
  }

  
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--body_color);
  }

  .skills h1 {
    margin-top: 100px;
    font-size: 50px;
    color: var(--yellow_color);
    text-shadow: 1px 1px 1px var(--navbar_color);
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
  }


  .hobbies {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--body_color);
  }

  .hobbies h1 {
    margin-top: 100px;
    font-size: 50px;
    color: var(--yellow_color);
    text-shadow: 1px 1px 1px var(--navbar_color);
  }
  
  .hobbies .list {
    list-style: none;
    width: 60%;
  }
  
  .list span {
    font-size: 20px;
  }

  hr.roundedDivLine {
    border-top: 3px solid #8794c0;
    border-radius: 5px;
    width: 90%;
    background-color: #1c2135;
  }


  
  @media only screen and (max-width: 750px) {
    .about h2 {
      font-size: 40px;
    }
  
    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
    .about .prompt svg {
      font-size: 40px;
    }
  
    .list {
      padding: 0;
    }

    .skills {
      text-align: center;
    }
    
    .skills h1 {
      font-size: 45px;
    }

    .skills h2 {
      font-size: 30px;
    }

    .skills .list {
      width: 90%;
    }

    .hobbies {
      text-align: center;
    }
    .hobbies h1 {
      font-size: 45px;
    }
    .hobbies h2 {
      font-size: 30px;
    }
    .hobbies .list {
      width: 90%;
    }
  }