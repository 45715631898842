.navbar {
    width: 100%;
    height: 100px;
    position: sticky;
    top: 0;
    z-index: 20;
    background: var(--navbar_background);
    float: left;
  }

  .left {
    float: left;
    width: 20%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--navbar_color);
  }

  .middle {
    float: left;
    width: 60%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--navbar_color);
  }

  .link {
    float: left;
    padding: 10px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link button{
    background-color: transparent;
    border: none;
    color: var(--navbar_color);
    cursor: pointer;
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .navbar a {
    color: var(--navbar_color);
    text-decoration: none;
    margin: 5px;
    font-size: 25px;
  }

  .right {
    float: left;
    width: 18%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--navbar_color);
  }
  .dropdownButton {
    width: 50px;
    height: 50px;
    display: none;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  .dropdownButton button {
    background-color: transparent;
    border: none;
    color: var(--navbar_color);
    cursor: pointer;
  }
  .dropdownButton svg {
    font-size: 50px;
  }

  #open {
    height: 100vh;
  }

  #open .middle {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
  }

  #open a {
    width: 100%;
    text-align: center;
  }

  @media only screen and (max-width: 750px) {

    .left{
      width: 50%;
    }
    .middle {
      width: 0%;
    }
    .right{
      width: 48%;
    }

    .dropdownButton {
      display: flex;
    }

    .link {
      display: none;
    }

    #open .link{
      display: flex;
    }
    #open .left{
      width: 20%;
    }
    #open .middle{
      width: 60%;
      display: flex;
    }
    #open .right{
      width: 18%;
    }
  }


  

  