.contact {
    height: auto;
    background-color: transparent;
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
}

.contact h1 {
    font-size: 50px;
    font-weight: 600;
    color: var(--yellow_color);
    text-align: left;
    margin-bottom: 20px;
    margin-left: 20%;
    margin-right: 20%;
    text-shadow: 1px 1px 1px var(--navbar_color);
}

.info {
    display: flex;
    margin-top: 20px;
    margin-left: 20%;
    margin-right: 20%;
}

.info .left{
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.info .contactInfo {
    width: 100%;
    margin-top: 10px;
    margin-left: 0%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 30px;
    color: var(--body_color);
}

.info .contactInfo:hover{
    background-color: var(--purple_color);
}

.info .contactInfo a{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}

.info .contactInfo svg {
    color: var(--body_color);
    margin: 20px;
    font-size: 40px;
    cursor: pointer;
}

.info .contactInfoText h3 {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--body_color);
}
.info .contactInfoText p {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: normal;
    color: var(--body_color);
}

.info .right{
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info .right img{
    width: 90%;
    height: auto;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 30px;
    border: 12px solid var(--navbar_background);
}

.info .right img:hover{
    
    opacity: 0.5;
}

.info .right p{
    position: relative;
    text-align: center;
    top:-200px;
    text-decoration: none;
    opacity: 1;
    cursor: auto;
    z-index: 0;
}


@media screen and (max-width: 750px) {
    .info {
        flex-direction: column;
        margin-left: 0% ;
        margin-right: 0%;
    }

    .contact h1 {
        font-size: 50px;
        font-weight: 600;
        color: var(--yellow_color);
        text-align: center;
        margin-bottom: 20px;
        margin-left: 0%;
        margin-right: 0%;
    }

    .info .left {
        width: 100%;
    }
    .info .right {
        width: 100%;
    }

    .info .contactInfo {
        margin-top: 10px;
        margin-left: 0%;
    }
}
